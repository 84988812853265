<template>
  <a-table
    class="custom-table"
    :columns="columns"
    :data-source="dataSource"
    row-key="name"
    :pagination="false"
    :loading="loading"
    :scroll="{ x: scrollX,  y: 720 }"
    :components="components"
  >
    <template slot="name" slot-scope="text">
      <a-tooltip v-if="text" :title="text">{{ text }}</a-tooltip>
      <span v-else>-</span>
    </template>
    <template slot="version" slot-scope="text">
      <a-tooltip v-if="text" :title="text">{{ text }}</a-tooltip>
      <span v-else>-</span>
    </template>
    <template slot="description" slot-scope="text">
      <a-tooltip v-if="text" :title="text">{{ text }}</a-tooltip>
      <span v-else>-</span>
    </template>
    <template slot="operation" slot-scope="text, record, index">
      <edit-button
        @click="$emit('edit', record)"
      ></edit-button>
      <delete-button @confirm="confirm(record.id, index)"></delete-button>
    </template>
  </a-table>
</template>

<script>
import { getOSSoftwareList, deleteOSSoftware } from '@/api/os'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'

export default {
  name: 'InfoTable',
  props: {
    detail: {
      type: Object
    },
    refresh: {
      type: Boolean
    }
  },
  components: {
    DeleteButton,
    EditButton
  },
  data () {
    return {
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'version',
          title: '版本',
          width: 140,
          ellipsis: true,
          scopedSlots: {
            customRender: 'version'
          }
        },
        {
          dataIndex: 'description',
          title: '描述',
          width: 180,
          ellipsis: true,
          scopedSlots: {
            customRender: 'description'
          }
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 100,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      dataSource: [],
      loading: false,
      components: {
        header: {}
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    confirm (id, index) {
      deleteOSSoftware(id).then(res => {
        this.$message.success(res.message)
        this.dataSource.splice(index, 1)
      })
    },
    fetch () {
      this.loading = true
      getOSSoftwareList(this.detail.id).then(res => {
        this.dataSource = res.data.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    refresh () {
      this.fetch()
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar) {
  display: none !important;
  border: none !important;
}
</style>
