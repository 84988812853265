<template>
  <a-descriptions
    :column="1"
    class="description-overflow-hidden description-item-12px"
  >
    <a-descriptions-item label="名称">
      <content-tooltip
        v-if="detail.name"
        :title="detail.name"
        :body="detail.name"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="类型">
      <content-tooltip
        v-if="detail.os_type"
        :title="$t(`os_type.${detail.os_type}`)"
        :body="$t(`os_type.${detail.os_type}`)"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="版本">
      <content-tooltip
        v-if="detail.version"
        :title="detail.version"
        :body="detail.version"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="CPU">
      <content-tooltip
        v-if="detail.cpu"
        :title="detail.cpu+' 核'"
        :body="detail.cpu+' 核'"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="内存">
      <content-tooltip
        v-if="detail.memory"
        :title="detail.memory+' GB'"
        :body="detail.memory+' GB'"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="磁盘" v-if="showMore && detail.disk">
      <content-tooltip
        v-if="detail.disk"
        :title="detail.disk+' GB'"
        :body="detail.disk+' GB'"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="位置" v-if="showMore">
      <content-tooltip
        v-if="detail.location"
        :title="detail.location"
        :body="detail.location"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="创建时间" v-if="showMore">
      <content-tooltip
        v-if="detail.created_at"
        :title="detail.created_at"
        :body="detail.created_at"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="更新时间" v-if="showMore">
      <content-tooltip
        v-if="detail.updated_at"
        :title="detail.updated_at"
        :body="detail.updated_at"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import ContentTooltip from '@/components/ContentTooltip'

export default {
  name: 'HardwareDescription',
  props: {
    detail: {
      type: Object
    },
    showMore: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ContentTooltip
  }
}
</script>

<style>

</style>
