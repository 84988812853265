<template>
  <a-table
    class="custom-table small-custom-table scroll-hidden-table"
    :columns="columns"
    :data-source="dataSource"
    :pagination="{pageSize: 4, size: 'small', total:total,  showTotal: total => `共 ${total} 条`}"
    row-key="id"
    :scroll="{x: scrollX}"
    :total="total"
    :loading="loading"
    :components="components"
  >
    <template slot="duration" slot-scope="text, record">{{ duration(record.triggered_at, record.recovered_at) }}</template>
    <template slot="severity" slot-scope="text">
      <alert-severity-tag :severity="text"></alert-severity-tag>
    </template>
    <template slot="name" slot-scope="text">
      <content-tooltip v-if="text" :title="text" :body="text"></content-tooltip>
      <span v-else>-</span>
    </template>
  </a-table>
</template>

<script>
import { humanAlertDuration } from '@/utils'
import { getOSAlertList } from '@/api/os'
import ContentTooltip from '@/components/ContentTooltip'
import AlertSeverityTag from '@/components/tag/AlertSeverityTag'

export default {
  name: 'AlertTable',
  components: {
    ContentTooltip,
    AlertSeverityTag
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  data () {
    return {
      loading: false,
      columns: [
        {
          dataIndex: 'severity',
          title: '严重性',
          width: 90,
          scopedSlots: {
            customRender: 'severity'
          }
        },
        {
          dataIndex: 'name',
          title: '告警内容',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'created_at',
          title: '触发时间',
          width: 180
        },
        {
          dataIndex: 'duration',
          title: '持续时间',
          width: 128,
          scopedSlots: {
            customRender: 'duration'
          }
        }
      ],
      total: 0,
      dataSource: [],
      components: {
        header: {}
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      const params = {
        name: '网口',
        recovered: false
      }
      getOSAlertList(params).then(res => {
        const data = res.data
        this.total = data.total
        this.dataSource = data.data
      }).finally(() => {
        this.loading = false
      })
    },
    duration (triggeredAt, recoveredAt) {
      return humanAlertDuration(triggeredAt, recoveredAt)
    }
  }
}
</script>
