<template>
  <a-table
    class="custom-table scroll-hidden-table"
    style="margin-top: 16px"
    :columns="columns"
    :data-source="dataSource"
    :scroll="{ x: 'auto' }"
    :pagination="{ pageSize: 10 }"
    row-key="pid"
    :loading="loading"
    :components="components"
  >
    <div v-for="item in filterColumns" :key="item.dataIndex">
      <template :slot="item.scopedSlots.customRender" slot-scope="text">
        <a-tooltip :title="text">
          <div class="text-overflow-multiline">{{ text }}</div>
        </a-tooltip>
      </template>
    </div>
    <template slot="operation" slot-scope="text, record">
      <a-button
        icon="monitor"
        size="small"
        type="link"
        @click="monitor(record)"
        style="color: #1890ff"
      >
        监控
      </a-button>
    </template>
  </a-table>
</template>

<script>
import { getOSMonitorItemList } from '@/api/os'

export default {
  name: 'ProcessesMonitorTable',
  props: {
    detail: {
      type: Object
    },
    filterList: {
      type: Object
    },
    searchRefresh: {
      type: Boolean
    }
  },
  computed: {
    filterColumns () {
      return this.columns.filter(item => item.scopedSlots && item.scopedSlots.customRender !== 'operation')
    }
  },
  data () {
    return {
      loading: false,
      columns: [
        {
          dataIndex: 'pid',
          title: 'PID',
          width: 100,
          align: 'center',
          scopedSlots: {
            customRender: 'pid'
          }
        },
        {
          dataIndex: 'name',
          title: '名称',
          width: 160,
          scopedSlots: {
            customRender: 'name'
          },
          filteredValue: [],
          onFilter: (value, record) => {
            return record.name.toString().toLowerCase().includes(value.toLowerCase())
          }
        },
        {
          dataIndex: 'user',
          title: '用户',
          width: 120,
          scopedSlots: {
            customRender: 'user'
          },
          filteredValue: [],
          onFilter: (value, record) => {
            return record.user.toString().toLowerCase().includes(value.toLowerCase())
          }
        },
        {
          dataIndex: 'cmdline',
          title: '命令行',
          width: 460,
          scopedSlots: {
            customRender: 'cmdline'
          },
          filteredValue: [],
          onFilter: (value, record) => {
            return record.cmdline.toString().toLowerCase().includes(value.toLowerCase())
          }
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 90,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      components: {
        header: {}
      },
      dataSource: []
    }
  },
  mounted () {
    this.fetchProcesses()
  },
  methods: {
    fetchProcesses () {
      this.loading = true
      getOSMonitorItemList(this.detail.id, { key: 'proc.info' }).then(res => {
        const v = res.data.data.length ? res.data.data[0].value : ''
        this.dataSource = v ? JSON.parse(v) : []
      }).finally(() => {
        this.loading = false
      })
    },
    monitor (column) {
      this.$emit('edit', column)
    }
  },
  watch: {
    searchRefresh (v) {
      for (const key in this.filterList) {
        if (Object.hasOwnProperty.call(this.filterList, key)) {
          const value = this.filterList[key]
          const index = this.columns.findIndex(column => column.dataIndex === key)
          if (value) {
            this.columns[index].filteredValue = [value]
          } else this.columns[index].filteredValue = []
        }
      }
    }
  }
}
</script>
