<template>
  <a-table
    :columns="columns"
    :data-source="dataSource"
    :pagination="pagination ? { pageSize: pageSize, total: dataSource.length, showTotal: v => `共 ${v} 条` } : false"
    row-key="id"
    :scroll="{ x: scrollX }"
    :loading="loading"
    class="custom-table"
  >
    <template slot="name" slot-scope="record">
      <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
    </template>
    <template slot="size" slot-scope="record">{{ `${record} GB` }}</template>
    <template slot="used" slot-scope="record">{{ `${record} GB` }}</template>
    <template slot="usage" slot-scope="text">
      <div style="width: 90%">
        <usage-progress :percent="text ? parseFloat(text.toFixed(2)) : 0"></usage-progress>
      </div>
    </template>
    <template slot="status" slot-scope="text">
      <source-status-tag :status="text"></source-status-tag>
    </template>
    <template slot="operation" slot-scope="text, record">
      <a-button
        icon="area-chart"
        size="small"
        type="link"
        @click="e => $emit('click', record)"
      >
        查看
      </a-button>
    </template>
  </a-table>
</template>

<script>
import UsageProgress from '@/components/progress/UsageProgress'
import SourceStatusTag from '@/components/tag/SourceStatusTag'

export default {
  name: 'DiskSimpleTable',
  components: {
    SourceStatusTag,
    UsageProgress
  },
  props: {
    dataSource: {
      type: Array
    },
    sourceType: {
      type: String
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pagination: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    showOperation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    columns () {
      const columns = [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'size',
          title: '容量',
          width: 120,
          scopedSlots: {
            customRender: 'size'
          }
        }
      ]
      if (this.sourceType !== 'storage') {
        columns.push(
          {
            dataIndex: 'used',
            title: '已使用',
            width: 120,
            scopedSlots: {
              customRender: 'used'
            }
          },
          {
            dataIndex: 'usage',
            title: '使用率',
            width: 200,
            scopedSlots: {
              customRender: 'usage'
            }
          }
        )
      }
      if (this.sourceType !== 'os' && this.sourceType !== 'hypervisor') {
        columns.push({
          dataIndex: 'status',
          align: 'center',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'status'
          }
        })
      }
      if (this.showOperation) {
        columns.push(
          {
            dataIndex: 'operation',
            title: '操作',
            width: 90,
            align: 'center',
            scopedSlots: {
              customRender: 'operation'
            }
          }
        )
      }
      return columns
    },
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  }
}
</script>
