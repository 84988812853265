<template>
  <div>
    <div style="margin-bottom: 16px">
      <a-space>
        <a-button type="primary" @click="visible = true">添加进程</a-button>
        <a-button @click="processesListRefresh = !processesListRefresh">刷新</a-button>
      </a-space>
    </div>
    <a-alert
      v-if="detail && detail.os_type === 'windows'"
      message="Windows 进程暂不支持获取 CPU 使用率 和内存使用率"
      type="info"
      show-icon
      style="margin-bottom: 12px"
    />
    <processes-table v-if="detail" :detail="detail" :refresh="processesListRefresh"></processes-table>

    <a-modal
      title="选择进程"
      :visible="visible"
      @cancel="handleCancel"
      :destroy-on-close="true"
      :width="1020"
      :body-style="{
        padding: '12px 16px'
      }"
    >
      <template #footer>
        <a-space size="middle">
          <a-button :loading="loading" type="primary" v-if="activeStep === 1" @click="handleOk">
            确定
          </a-button>
          <a-button @click="handleCancel">取消</a-button>
        </a-space>
      </template>

      <a-steps :current="activeStep" size="small" style="padding: 16px 80px">
        <a-step title="选择进程">
          <a-icon slot="icon" type="select" />
        </a-step>
        <a-step title="编辑进程信息">
          <a-icon slot="icon" type="form" />
        </a-step>
      </a-steps>

      <!-- 监控端口表格 -->
      <template v-if="activeStep === 0">
        <a-form-model :model="form" v-bind="layout">
          <a-row :gutter="48">
            <a-col :span="12">
              <a-form-model-item label="用户" style="margin-bottom: 8px">
                <a-input v-model="form.user" placeholder="请输入关键字" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="命令行" style="margin-bottom: 8px">
                <a-input v-model="form.cmdline" placeholder="请输入关键字" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="名称" style="margin-bottom: 8px">
                <a-input v-model="form.name" placeholder="请输入关键字" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                :wrapperCol="{ span: 24 }"
                style="text-align: right; margin-bottom: 8px"
              >
                <a-space>
                  <a-button type="default" @click="clearSearch"> 重置 </a-button>
                  <a-button type="primary" @click="search"> 查询 </a-button>
                </a-space>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <processes-monitor-table
          :filterList="searchObj"
          :detail="detail"
          @edit="v => editInfo(v)"
          :searchRefresh="searchRefresh"
        ></processes-monitor-table>
      </template>

      <!-- 编辑端口信息表单 -->
      <template v-if="activeStep === 1">
        <a-form-model
          ref="editForm"
          :label-col="{
            span: 4,
            style: {
              textAlign: 'left'
            }
          }"
          :model="editForm"
          :rules="editRules"
          :wrapper-col="{ span: 20 }"
          style="padding: 24px 24px 0px 24px"
        >
          <a-form-model-item label="PID" prop="pid">
            <a-input v-model="editForm.pid" :max-length="128" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="名称" prop="name">
            <a-input v-model="editForm.name" :max-length="128" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="用户" prop="user">
            <a-input v-model="editForm.user" :max-length="128" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item prop="cmdline">
            <question-icon
              slot="label"
              title="命令行"
              description="支持正则匹配"
            ></question-icon>
            <a-textarea v-model="editForm.cmdline" :auto-size="{ minRows: 5 }"></a-textarea>
          </a-form-model-item>
        </a-form-model>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { createOSMonitorProcesses } from '@/api/os'
import ProcessesTable from './modules/ProcessesTable.vue'
import ProcessesMonitorTable from './modules/ProcessesMonitorTable.vue'
import QuestionIcon from '@/components/icon/QuestionIcon'

export default {
  name: 'ProcessesPage',
  props: {
    detail: {
      type: Object
    }
  },
  components: { ProcessesTable, ProcessesMonitorTable, QuestionIcon },
  data () {
    return {
      activeStep: 0,
      loading: false,
      visible: false,
      dataSource: [],
      searchRefresh: false,
      processesListRefresh: false,
      selectedRowKeys: [],
      form: {
        cmdline: '',
        user: '',
        name: ''
      },
      editForm: {
        name: '',
        pid: '',
        cmdline: '',
        user: ''
      },
      editRules: {
        cmdline: [
          {
            message: '请输入命令行',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      searchObj: {
        cmdline: '',
        user: '',
        name: ''
      },
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      }
    }
  },
  mounted () {
  },
  methods: {
    search () {
      this.searchObj = JSON.parse(JSON.stringify(this.form))
      this.searchRefresh = !this.searchRefresh
    },
    clearSearch () {
      this.form = {
        cmdline: '',
        user: '',
        name: ''
      }
      this.searchObj = JSON.parse(JSON.stringify(this.form))
      this.searchRefresh = !this.searchRefresh
    },
    editInfo (info) {
      this.editForm.name = info.name
      this.editForm.pid = info.pid
      this.editForm.cmdline = info.cmdline
      this.editForm.user = info.user
      this.activeStep = 1
      if (!this.visible) this.visible = true
    },
    handleOk () {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.loading = true
          createOSMonitorProcesses(this.detail.id, {
            name: this.editForm.name,
            cmdline: this.editForm.cmdline,
            user: this.editForm.user
          }).then(res => {
            this.$message.success(res.message)
            this.handleCancel()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    handleCancel () {
      this.visible = false
      this.activeStep = 0
      this.editForm = {
        name: '',
        pid: '',
        cmdline: '',
        user: ''
      }
      this.form = {
        cmdline: '',
        user: '',
        name: ''
      }
      this.searchObj = JSON.parse(JSON.stringify(this.form))
      this.processesListRefresh = !this.processesListRefresh
    }
  }
}
</script>
