var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"margin-bottom":"16px"}},[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.visible = true}}},[_vm._v("添加软件")]),_c('a-button',{on:{"click":function($event){_vm.refresh = !_vm.refresh}}},[_vm._v("刷新")])],1)],1),(_vm.detail.id)?_c('info-table',{attrs:{"detail":_vm.detail,"refresh":_vm.refresh},on:{"edit":v => _vm.editInfo(v)}}):_vm._e(),_c('a-modal',{attrs:{"title":"添加软件","visible":_vm.visible,"destroy-on-close":true,"width":750,"body-style":{
      padding: '24px 32px'
    }},on:{"cancel":_vm.handleCancel,"ok":_vm.handleOk}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
        span: 4,
        style: {
          textAlign: 'left'
        }
      },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{ span: 20 }}},[_c('a-form-model-item',{attrs:{"label":"名称","prop":"name","disabled":_vm.editId}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"版本","prop":"version"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.version),callback:function ($$v) {_vm.$set(_vm.form, "version", $$v)},expression:"form.version"}})],1),_c('a-form-model-item',{attrs:{"label":"描述","prop":"description"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }