<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      :row-key="(record, index) => `${record.cmdline}-${index}`"
      :scroll="{ x: scrollX, y: 720 }"
      :loading="loading"
      class="custom-table scroll-hidden-table"
      :components="components"
    >
      <template slot="name" slot-scope="text">
        <content-tooltip
          v-if="text"
          :title="text"
          :body="text"
        ></content-tooltip>
        <span v-else>-</span>
      </template>
      <template slot="cmdline" slot-scope="text">
        <content-tooltip
          v-if="text"
          :title="text"
          :body="text"
        ></content-tooltip>
        <span v-else>-</span>
      </template>
      <template slot="user" slot-scope="text">
        <content-tooltip
          v-if="text"
          :title="text"
          :body="text"
        ></content-tooltip>
        <span v-else>-</span>
      </template>
      <template slot="md5" slot-scope="text">
        <content-tooltip
          v-if="text"
          :title="text"
          :body="text"
        ></content-tooltip>
        <span v-else>-</span>
      </template>
      <template slot="cpu" slot-scope="text, record">
        <span v-if="detail.os_type === 'linux'">
          <content-tooltip
            v-if="text || text === 0"
            :title="text"
            :body="text"
          ></content-tooltip>
          <span v-else>0</span>
          <a
            v-if="record"
            @click="displayChart(record, 'cpu')"
            style="margin-left: 8px; font-size: 12px"
          >
            <a-icon type="area-chart" />
          </a>
        </span>
        <span v-else>-</span>
      </template>
      <template slot="memory" slot-scope="text, record">
        <span v-if="detail.os_type === 'linux'">
          <content-tooltip
            v-if="text || text === 0"
            :title="text"
            :body="text"
          ></content-tooltip>
          <span v-else>0</span>
          <a
            v-if="record"
            @click="displayChart(record, 'memory')"
            style="margin-left: 8px; font-size: 12px"
          >
            <a-icon type="area-chart" />
          </a>
        </span>
        <span v-else>-</span>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <delete-button @confirm="confirm(record.id, index)"></delete-button>
      </template>
    </a-table>
    <multi-line-chart-modal
      ref="chartModal"
      :history-func="historyFunc"
      :source-id="sourceId"
    ></multi-line-chart-modal>
  </div>
</template>

<script>
import {
  getOSMonitorProcessesList,
  deleteOSMonitorProcesses,
  getOSMonitorItemList,
  getOSMonitorHistory
} from '@/api/os'
import DeleteButton from '@/components/button/DeleteButton'
import ContentTooltip from '@/components/ContentTooltip.vue'
import MultiLineChartModal from '@/components/modal/MultiLineChartModal'

export default {
  name: 'ProcessesTable',
  props: {
    detail: {
      type: Object
    },
    refresh: {
      type: Boolean
    }
  },
  components: { DeleteButton, ContentTooltip, MultiLineChartModal },
  data () {
    return {
      loading: false,
      dataSource: [],
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 100,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'user',
          title: '用户',
          width: 80,
          ellipsis: true,
          scopedSlots: {
            customRender: 'user'
          }
        },
        {
          dataIndex: 'cmdline',
          title: '命令行',
          width: 240,
          ellipsis: true,
          scopedSlots: {
            customRender: 'cmdline'
          }
        },
        {
          dataIndex: 'md5',
          title: 'md5',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'md5'
          }
        },
        {
          dataIndex: 'cpu',
          title: 'CPU 使用率 (%)',
          width: 120,
          align: 'center',
          scopedSlots: {
            customRender: 'cpu'
          }
        },
        {
          dataIndex: 'memory',
          title: '内存使用率 (%)',
          width: 120,
          align: 'center',
          scopedSlots: {
            customRender: 'memory'
          }
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 90,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      sourceId: this.detail.id,
      historyFunc: getOSMonitorHistory,
      historyItems: {
        cpu: {
          title: 'CPU 使用率'
        },
        memory: {
          title: '内存使用率'
        }
      },
      components: {
        header: {}
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      getOSMonitorProcessesList(this.detail.id)
        .then(res => {
          this.dataSource = res.data.data.map(item => {
            return {
              ...item,
              cpu: 0,
              memory: 0
            }
          })
          this.dataSource.forEach(item => {
            const keys = this.getMonitorKeys(item)
            this.updateMonitorValues(keys).then(res => {
              item.cpu = res[0]
              item.memory = res[1]
            })
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    confirm (id, index) {
      deleteOSMonitorProcesses(id).then(res => {
        this.$message.success(res.message)
        this.dataSource.splice(index, 1)
      })
    },
    updateMonitorValues (keys) {
      return Promise.all(
        keys.map(
          async keyObj =>
            new Promise((resolve, reject) => {
              getOSMonitorItemList(this.detail.id, { key: keyObj.key }).then(
                res => {
                  const data = res.data.data
                  const value = data.length ? data[0].value : 0
                  resolve(value)
                }
              )
            })
        )
      )
    },
    getMonitorKeys (item) {
      return [
        {
          name: 'cpu',
          key: `proc.cpu.util["${item.name}","${item.user}",,"${item.cmdline}"]`
        },
        {
          name: 'memory',
          key: `proc.mem["${item.name}","${item.user}",,"${item.cmdline}",pmem]`
        }
      ]
    },
    displayChart (record, type) {
      this.sourceId = this.detail.id
      this.$refs.chartModal.show({
        title: `${this.historyItems[type].title} (${record.name})`,
        key: this.getKeys(record).find(item => item.dataIndex === type).key
      })
    },
    getKeys (item) {
      return [
        {
          key: `proc.cpu.util["${item.name}","${item.user}",,"${item.cmdline}"]`,
          dataIndex: 'cpu'
        },
        {
          key: `proc.mem["${item.name}","${item.user}",,"${item.cmdline}",pmem]`,
          dataIndex: 'memory'
        }
      ]
    }
  },
  watch: {
    refresh () {
      this.fetch()
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar) {
  display: none !important;
  border: none !important;
}
</style>
