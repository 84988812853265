<template>
  <div class="filesystem-page">
    <!-- <div
      style="
        height: 32px;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 32px;
      "
    >
      <a-space size="middle" style="float: right">
        <reload-button @click="fetch()"></reload-button>
      </a-space>
    </div> -->

    <div class="module-container" :style="{ height: '446px'}">
      <header>
        文件系统列表
      </header>
      <disk-simple-table :dataSource="dataSource" source-type="os" :page-size="5" :loading="loading" :show-operation="true" @click="v => changeUsgaeChart(v)"></disk-simple-table>
    </div>

    <div class="module-container" :style="{ height: '450px', marginTop: '16px'}">
      <header>
        使用率趋势图 ( {{ selectedFilesystem ? selectedFilesystem.name : '' }} )
      </header>

      <a-space
        :size="8"
        style="
          margin-bottom: 24px;
          position: absolute;
          right: 20px;
          top: 18px;
        "
      >
        <a-checkbox v-model="compareYesterday">
          <question-icon
            title="环比"
            description="昨天同时段"
          ></question-icon>
        </a-checkbox>
        <a-checkbox v-model="compareLastWeek">
          <question-icon
            title="同比"
            description="上周同时段"
          ></question-icon>
        </a-checkbox>
        <datetime-range
          style="width: 300px"
          :allow-clear="false"
          :default-value="datetimeRange"
          @ok="
            v => {
              datetimeRange = v
            }
          "
        ></datetime-range>
      </a-space>

      <multi-line-chart
        v-if="selectedFilesystem"
        chart-id="filesystem-usage-chart"
        :source-id="detail.id"
        :height="370"
        :history-func="historyFunc"
        :datetime-range="datetimeRange"
        :compare-last-week="compareLastWeek"
        :compare-yesterday="compareYesterday"
        :itemKey="itemKey"
        :max-value="100"
        style="padding: 4px 4px 0 6px"
      ></multi-line-chart>
      <a-empty v-else :description="false" style="margin-top: 148px;"></a-empty>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment'
import {
  getOSFilesystemList,
  getOSMonitorHistory
} from '@/api/os'
// import ReloadButton from '@/components/button/ReloadButton'
import DiskSimpleTable from '@/components/table/DiskSimpleTable.vue'
import DatetimeRange from '@/components/DatetimeRange'
import QuestionIcon from '@/components/icon/QuestionIcon'
import MultiLineChart from '@/components/chart/MultiLineChart'

export default {
  name: 'FilesystemPage',
  components: {
    // ReloadButton,
    DiskSimpleTable,
    DatetimeRange,
    QuestionIcon,
    MultiLineChart
  },
  props: {
    detail: {
      type: Object
    }
  },
  data () {
    return {
      dataSource: [],
      loading: false,
      compareYesterday: false,
      compareLastWeek: false,
      datetimeRange: [moment().subtract(6, 'hour'), moment()],
      historyFunc: getOSMonitorHistory,
      selectedFilesystem: undefined,
      itemKey: ''
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      getOSFilesystemList(this.detail.id).then(res => {
        const data = res.data
        this.dataSource = data.data
        if (this.dataSource.length) {
          this.selectedFilesystem = this.dataSource[0]
          this.itemKey = this.getKey(this.selectedFilesystem)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    getKey (obj) {
      return `vfs.fs.size[${obj.name},pused]`
    },
    changeUsgaeChart (recored) {
      this.selectedFilesystem = recored
      this.itemKey = this.getKey(this.selectedFilesystem)
    }
  }
}
</script>

<style lang="less">
.filesystem-page {
  margin-right: 1px;
}
</style>
