<template>
  <div>
    <div style="margin-bottom: 16px">
      <a-space>
        <a-button type="primary" @click="visible = true">添加软件</a-button>
        <a-button @click="refresh = !refresh">刷新</a-button>
      </a-space>
    </div>

    <info-table v-if="detail.id" @edit="v => editInfo(v)" :detail="detail" :refresh="refresh"></info-table>

    <a-modal
      title="添加软件"
      :visible="visible"
      @cancel="handleCancel"
      @ok="handleOk"
      :destroy-on-close="true"
      :width="750"
      :body-style="{
        padding: '24px 32px'
      }"
    >
      <a-form-model
        ref="form"
        :label-col="{
          span: 4,
          style: {
            textAlign: 'left'
          }
        }"
        :model="form"
        :rules="rules"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item label="名称" prop="name" :disabled="editId">
          <a-input v-model="form.name" :max-length="128"></a-input>
        </a-form-model-item>
        <a-form-model-item label="版本" prop="version">
        <a-input v-model="form.version" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input v-model="form.description" :max-length="128"></a-input>
      </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { createOSSoftware, updateOSSoftware } from '@/api/os'
import InfoTable from './modules/InfoTable.vue'

export default {
  name: 'SoftwareInfoPage',
  props: {
    detail: {
      type: Object
    }
  },
  components: {
    InfoTable
  },
  data () {
    return {
      visible: false,
      refresh: false,
      editId: '',
      form: {
        name: '',
        version: '',
        description: ''
      },
      rules: {
        name: [
          {
            message: '请输入软件名称',
            required: true,
            trigger: 'blur'
          }
        ],
        version: [
          {
            message: '请输入软件版本',
            required: true,
            trigger: 'blur'
          }
        ],
        description: [
          {
            message: '请输入软件描述',
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    handleCancel () {
      this.visible = false
      this.form = {
        name: '',
        version: '',
        description: ''
      }
      this.editId = ''
    },
    handleOk () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            version: this.form.version,
            description: this.form.description
          }
          if (this.editId) {
            updateOSSoftware(this.editId, params).then(res => {
              this.$message.success(res.message)
              this.refresh = !this.refresh
            }).catch(() => {})
          } else {
            params.name = this.form.name
            createOSSoftware(this.detail.id, params).then(res => {
              this.$message.success(res.message)
              this.refresh = !this.refresh
            }).catch(() => {})
          }
          this.handleCancel()
        }
      })
    },
    editInfo (info) {
      this.form.name = info.name
      this.form.version = info.version
      this.form.description = info.description
      this.editId = info.id
      if (!this.visible) this.visible = true
    }
  }
}
</script>

<style>

</style>
