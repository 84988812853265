<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="{ pageSize: 5 }"
      row-key="id"
      :scroll="{ x: scrollX }"
      class="custom-table"
    >
      <template slot="name" slot-scope="record">
        <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
      </template>
      <template slot="os" slot-scope="text">
        <a-tooltip placement="topLeft" :title="text.name">
          <a v-if="text" @click="$refs.osDrawer.show(text.id)">
            {{ text.name }}
          </a>
          <span v-else>-</span>
        </a-tooltip>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button
          icon="link"
          size="small"
          type="link"
          @click="$refs.modal.show(record)"
        >
          关联
        </a-button>
      </template>
    </a-table>

    <link-modal ref="modal" :hypervisor-id="hypervisorId"></link-modal>
    <os-drawer ref="osDrawer"></os-drawer>
  </div>
</template>

<script>
import OSDrawer from '@/components/drawer/OSDrawer'
import LinkModal from './modules/LinkModal'

export default {
  name: 'VirtualMachineTable',
  components: {
    LinkModal,
    'os-drawer': OSDrawer
  },
  props: {
    dataSource: {
      type: Array
    },
    hypervisorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'monitor_address',
          title: 'IP 地址',
          width: 160
        },
        {
          dataIndex: 'datacenter',
          title: '数据中心',
          width: 200,
          ellipsis: true
        },
        {
          dataIndex: 'os',
          title: '关联操作系统',
          width: 200,
          scopedSlots: {
            customRender: 'os'
          }
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 240,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ]
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  }
}
</script>
