<template>
  <a-radio-group
    id="severity-radio-button"
    button-style="solid"
    size="small"
    :value="value"
    @change="e => $emit('input', e.target.value)"
    style="font-size: 12px"
  >
    <a-radio-button value="all">全部</a-radio-button>
    <a-radio-button
      v-for="severity in ['disaster', 'high', 'average', 'warning']"
      :key="severity"
      :value="severity"
    >
      {{ $t(`alert_severity.${severity}`) }}
    </a-radio-button>
  </a-radio-group>
</template>

<script>
export default {
  name: 'SeverityRadioGroup',
  props: {
    value: {
      type: String,
      default: 'all'
    }
  }
}
</script>
<style lang="less">
#severity-radio-button {
  width: 517px;
  height: 32px;
}
</style>
