<template>
  <a-table
    class="custom-table scroll-hidden-table"
    style="margin-top: 16px"
    :columns="columns"
    :data-source="dataSource"
    :pagination="{ pageSize: 10 }"
    row-key="local_address"
    :scroll="{ x: 'auto' }"
    :components="components"
    :loading="loading"
  >
    <template v-for="item in filterColumns" :slot="item.scopedSlots.customRender" slot-scope="text">
      <a-tooltip :title="text" :key="item.dataIndex">
        <div class="text-overflow-multiline">{{ text }}</div>
      </a-tooltip>
    </template>
    <template slot="operation" slot-scope="text, record">
      <a-button
        icon="monitor"
        size="small"
        type="link"
        @click="monitor(record)"
        style="color: #1890ff"
      >
        监控
      </a-button>
    </template>
  </a-table>
</template>

<script>
import { getOSMonitorItemList } from '@/api/os'

export default {
  name: 'MonitorTable',
  props: {
    detail: {
      type: Object
    }
  },
  computed: {
    filterColumns () {
      return this.columns.filter(item => item.scopedSlots && item.scopedSlots.customRender !== 'operation')
    }
  },
  data () {
    return {
      loading: false,
      columns: [
        {
          dataIndex: 'local_address',
          title: '本地地址',
          width: 140,
          scopedSlots: {
            customRender: 'localAddress'
          }
        },
        {
          dataIndex: 'remote_address',
          title: '映射地址',
          width: 140,
          scopedSlots: {
            customRender: 'remoteAddress'
          }
        },
        {
          dataIndex: 'process',
          title: '进程',
          width: 160,
          scopedSlots: {
            customRender: 'process'
          }
        },
        {
          dataIndex: 'protocol',
          title: '协议',
          width: 80,
          scopedSlots: {
            customRender: 'protocol'
          }
        },
        {
          dataIndex: 'state',
          title: '状态',
          width: 90,
          scopedSlots: {
            customRender: 'state'
          }
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          width: 90,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      dataSource: [],
      components: {
        header: {}
      }
    }
  },
  mounted () {
    this.fetchProcesses()
  },
  methods: {
    fetchProcesses () {
      this.loading = true
      this.loading = true
      getOSMonitorItemList(this.detail.id, { key: 'net.tcp.listen.info' }).then(res => {
        const v = res.data.data.length ? res.data.data[0].value : ''
        this.dataSource = v ? JSON.parse(v) : []
      }).finally(() => {
        this.loading = false
      })
    },
    monitor (column) {
      this.$emit('edit', column)
    }
  }
}
</script>
