<template>
  <div>
    <div style="margin-bottom: 16px">
      <a-space>
        <a-button type="primary" @click="visible = true">添加端口</a-button>
        <a-button @click="refresh = !refresh">刷新</a-button>
      </a-space>
    </div>

    <port-table @edit="v => editInfo(v)" v-if="detail.id" :detail="detail" :refresh="refresh"></port-table>

    <a-modal
      :title="activeStep ? '编辑端口' : '添加端口'"
      :visible="visible"
      @cancel="handleCancel"
      :destroy-on-close="true"
      :width="1020"
      :body-style="{ padding:  '16px 24px 32px 24px' }"
    >
      <template #footer>
        <a-space size="middle">
          <a-button
            v-if="activeStep === 1"
            :loading="loading"
            type="primary"
            @click="handleOk"
          >
            确定
          </a-button>
          <a-button @click="handleCancel">取消</a-button>
        </a-space>
      </template>

      <a-steps :current="activeStep" size="small" style="padding: 16px 80px">
        <a-step title="选择端口">
          <a-icon slot="icon" type="select"></a-icon>
        </a-step>
        <a-step title="编辑端口信息">
          <a-icon slot="icon" type="form"></a-icon>
        </a-step>
      </a-steps>

      <!-- 监控端口表格 -->
      <template v-if="activeStep === 0">
        <monitor-table
          :detail="detail"
          @edit="v => editInfo(v)"
          style="min-height: 200px"
        ></monitor-table>
      </template>

      <!-- 编辑端口信息表单 -->
      <template v-if="activeStep === 1">
        <a-form-model
          ref="form"
          :label-col="{
            span: 4,
            style: {
              textAlign: 'left'
            }
          }"
          :model="form"
          :rules="rules"
          :wrapper-col="{ span: 20 }"
          style="padding: 24px 24px 0px 24px"
        >
          <a-form-model-item label="本地地址" prop="localAddress">
            <a-input v-model="form.localAddress" :max-length="128" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="映射地址" prop="mapAddress">
            <a-input v-model="form.mapAddress" :max-length="128"></a-input>
          </a-form-model-item>
          <a-form-model-item label="描述" prop="description">
            <a-input v-model="form.description" :max-length="128"></a-input>
          </a-form-model-item>
        </a-form-model>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { createOSMonitorPort, updateOSMonitorPort } from '@/api/os.js'
import PortTable from './modules/PortTable.vue'
import MonitorTable from './modules/MonitorTable.vue'

export default {
  name: 'PortPage',
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  components: {
    PortTable,
    MonitorTable
  },
  data () {
    return {
      visible: false,
      activeStep: 0,
      form: {
        localAddress: '',
        mapAddress: '',
        description: ''
      },
      rules: {
        description: [
          {
            message: '请输入端口描述',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      editId: '',
      loading: false,
      refresh: false
    }
  },
  methods: {
    handleCancel () {
      this.visible = false
      this.activeStep = 0
      this.editId = ''
      this.form = {
        localAddress: '',
        mapAddress: '',
        description: ''
      }
    },
    handleOk () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            description: this.form.description
          }
          if (this.form.mapAddress) {
            params.map_address = this.form.mapAddress
          }
          if (this.editId) {
            updateOSMonitorPort(this.editId, params).then(res => {
              this.$message.success(res.message)
              this.refresh = !this.refresh
            }).catch(() => {})
          } else {
            params.local_address = this.form.localAddress
            createOSMonitorPort(this.detail.id, params).then(res => {
              this.$message.success(res.message)
              this.refresh = !this.refresh
            }).catch(() => {})
          }
          this.handleCancel()
        }
      })
    },
    editInfo (info) {
      this.form.localAddress = info.local_address
      this.form.description = info.description
      this.form.mapAddress = info.map_address
      this.editId = info.id
      this.activeStep = 1
      if (!this.visible) this.visible = true
    }
  }
}
</script>

<style></style>
