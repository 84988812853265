<template>
  <a-drawer
    class="detail-drawer"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1200"
    @close="() => {
      visible = false
      baseInfoDisplay = false
    }"
  >
    <a-tabs type="card">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          ref="sliderRow"
          :gutter="24"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <a-col :sm="0" :lg="4">
            <a-anchor
              :wrapper-style="{ background: 'transparent' }"
              :offset-top="16"
              :get-container="() => $refs.sliderRow.$el"
            >
              <a-anchor-link href="#detail" title="基本信息" />
              <a-anchor-link
                href="#alert"
                :title="`当前告警 (${count.alert})`"
              />
              <a-anchor-link href="#monitor" title="监控" />
            </a-anchor>
          </a-col>
          <a-col :sm="24" :lg="20">
            <div id="detail">
              <div class="small-module-title">
                <a-icon type="profile"></a-icon>
                <span> 基本信息</span>
              </div>

              <div style="padding: 0 16px">
                <a-descriptions :column="3">
                  <a-descriptions-item label="名称">
                    {{ detail.name }}
                  </a-descriptions-item>
                  <a-descriptions-item label="CPU">
                    {{ detail.cpu ? `${detail.cpu} 核` : '-' }}
                  </a-descriptions-item>
                  <a-descriptions-item label="内存">
                    {{ detail.memory ? `${detail.memory} GB` : '-' }}
                  </a-descriptions-item>
                  <a-descriptions-item label="版本">
                    {{ detail.version }}
                  </a-descriptions-item>
                  <a-descriptions-item label="创建时间">
                    {{ detail.created_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="更新时间">
                    {{ detail.updated_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="所属群组">
                    <a-tag v-for="group in detail.groups" :key="group.id">
                      {{ group.name }}
                    </a-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="状态">
                    <source-status-tag
                      :status="detail.status"
                    ></source-status-tag>
                  </a-descriptions-item>

                  <a-descriptions-item label="IP 地址" v-if="baseInfoDisplay">
                    {{ detail.monitor_address }}
                  </a-descriptions-item>
                  <a-descriptions-item label="监控状态" v-if="baseInfoDisplay">
                    <monitor-status-tag
                      v-for="item in monitorStatuses"
                      :key="item.name"
                      :status="item.value"
                      :name="item.name"
                    ></monitor-status-tag>
                  </a-descriptions-item>
                </a-descriptions>
                <div style="text-align: center; font-size: 12px; color: #096DD9; margin-bottom: 16px;">
                  <a @click="baseInfoDisplay = !baseInfoDisplay">
                    <a-icon theme="filled" :type="baseInfoDisplay ? 'caret-up': 'caret-down'"></a-icon>
                  </a>
                </div>
              </div>
            </div>
            <div id="alert">
              <div class="small-module-title">
                <a-icon type="alert"></a-icon>
                <span> 当前告警 ({{ count.alert }})</span>
              </div>

              <div style="padding: 0 16px">
                <div style="height: 24px; margin-bottom: 24px">
                  <severity-radio-group v-model="currentSeverity" style="float: right"></severity-radio-group>
                </div>

                <div style="max-height: 400px; overflow: scroll">
                  <alert-timeline
                    :severity="currentSeverity"
                    :source-id="detail.id"
                    source-type="os"
                    @total="v => (count.alert = v)"
                    style="margin-top: 8px"
                  ></alert-timeline>
                </div>
              </div>
            </div>
            <div id="monitor">
              <div class="small-module-title">
                <a-icon type="line-chart"></a-icon>
                <span> 监控</span>
              </div>

              <div style="padding: 0 16px">
                <div style="margin-bottom: 16px">
                  <datetime-range
                    :allow-clear="false"
                    :default-value="datetimeRange"
                    @ok="
                      v => {
                        datetimeRange = v
                      }
                    "
                  ></datetime-range>
                </div>

                <a-row :gutter="16">
                  <a-col
                    v-for="item in monitorItems"
                    :key="item.key"
                    :sm="24"
                    :lg="12"
                    style="margin-bottom: 16px"
                  >
                    <monitor-chart-card
                      :datetime-range="datetimeRange"
                      :history-func="historyFunc"
                      :item="item"
                      :source-id="detail.id"
                    ></monitor-chart-card>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="datastore" :tab="`数据存储 (${count.datastore})`">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <div
            style="
            height: 32px;
            margin-bottom: 16px;
            font-size: 14px;
            line-height: 32px;
          "
          >
            <a-space size="middle" style="float: right">
              <reload-button @click="fetch(detail.id, 'datastore')"></reload-button>
            </a-space>
          </div>

          <disk-simple-table
            :data-source="datastores"
            source-type="hypervisor"
          ></disk-simple-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="virtualMachine" :tab="`虚拟主机 (${count.virtualMachine})`">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <div
            style="
              height: 32px;
              margin-bottom: 16px;
              font-size: 14px;
              line-height: 32px;
            "
          >
            <a-space size="middle" style="float: right">
              <reload-button @click="fetch(detail.id, 'virtualMachine')"></reload-button>
            </a-space>
          </div>
          <virtual-machine-table
            :data-source="virtualMachines"
            :hypervisor-id="detail.id"
          ></virtual-machine-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="monitor" tab="监控指标">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <monitor-item-table
            :get-func="getItemListFunc"
            :history-func="historyFunc"
            :source-id="detail.id"
          ></monitor-item-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="rule" tab="告警规则">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <monitor-trigger-table
            :get-func="getTriggerFunc"
            :source-id="detail.id"
            :update-func="updateFunc"
          ></monitor-trigger-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import moment from 'moment'
import {
  getHypervisor,
  getHypervisorDatastoreList,
  getHypervisorMonitorHistory,
  getHypervisorMonitorItemList,
  getHypervisorMonitorStatusList,
  getHypervisorMonitorTriggerList,
  getHypervisorVirtualMachineList,
  updateHypervisorMonitorTrigger
} from '@/api/hypervisor'
import DatetimeRange from '@/components/DatetimeRange'
import ReloadButton from '@/components/button/ReloadButton'
import MonitorChartCard from '@/components/card/MonitorChartCard'
import SeverityRadioGroup from '@/components/radio/SeverityRadioGroup'
import DiskSimpleTable from '@/components/table/DiskSimpleTable'
import MonitorItemTable from '@/components/table/MonitorItemTable'
import MonitorTriggerTable from '@/components/table/MonitorTriggerTable'
import VirtualMachineTable from '@/components/table/VirtualMachineTable'
import MonitorStatusTag from '@/components/tag/MonitorStatusTag'
import SourceStatusTag from '@/components/tag/SourceStatusTag'

export default {
  name: 'HypervisorDrawer',
  components: {
    AlertTimeline: () => import('@/components/timeline/AlertTimeline'),
    DatetimeRange,
    DiskSimpleTable,
    MonitorChartCard,
    MonitorItemTable,
    MonitorStatusTag,
    MonitorTriggerTable,
    ReloadButton,
    SeverityRadioGroup,
    SourceStatusTag,
    VirtualMachineTable
  },
  data () {
    return {
      visible: false,
      baseInfoDisplay: false,
      currentSeverity: 'all',
      detail: {
        id: '',
        name: '',
        cpu: '',
        memory: '',
        version: '',
        monitor_address: '',
        created_at: '',
        updated_at: '',
        groups: [],
        status: 'unknown'
      },
      monitorStatuses: [],
      monitorItems: [
        {
          key: 'vmware.hv.cpu.usage.perf[{$VMWARE.URL},{$VMWARE.HV.UUID}]',
          title: 'CPU 使用率 (%)'
        }
      ],
      datastores: [],
      virtualMachines: [],
      datetimeRange: [moment().subtract(6, 'hour'), moment()],
      getItemListFunc: getHypervisorMonitorItemList,
      historyFunc: getHypervisorMonitorHistory,
      getTriggerFunc: getHypervisorMonitorTriggerList,
      updateFunc: updateHypervisorMonitorTrigger,
      count: {
        datastore: 0,
        virtualMachine: 0,
        alert: 0
      }
    }
  },
  methods: {
    show (id) {
      this.fetch(id, 'detail')
      this.fetch(id, 'status')
      this.fetch(id, 'datastore')
      this.fetch(id, 'virtualMachine')
    },
    fetch (id, item) {
      switch (item) {
        case 'detail':
          getHypervisor(id)
            .then(res => {
              this.detail = res.data
            })
            .finally(() => {
              this.visible = true
            })
          break
        case 'status':
          getHypervisorMonitorStatusList(id).then(res => {
            this.monitorStatuses = res.data.data
          })
          break
        case 'datastore':
          getHypervisorDatastoreList(id).then(res => {
            const data = res.data
            this.datastores = data.data
            this.count.datastore = data.total
          })
          break
        case 'virtualMachine':
          getHypervisorVirtualMachineList(id).then(res => {
            const data = res.data
            this.virtualMachines = data.data
            this.count.virtualMachine = data.total
          })
          break
      }
    }
  }
}
</script>
